$background: #eceef9;
$color:#7490BD;

/*Color variables*/
$primary:#7b41d8 ;
$secondary:#868e96;
$success:#2dce89 ;
$warning:#ecb403;
$danger:#f5365c;
$info:#45aaf2;
$pink:#ec82ef;
$teal:#2bcbba;
$purple:#867efc;
$orange:#fc7303;
$dark:#4d6a98;
$indigo:#6574cd;
$white:#ffffff;
$black:#000000;
$muted:#bdcce5;


/*shadow variables*/
$shadow: 0 0 0 1px rgba(61, 119, 180, 0.12), 0 8px 16px 0 rgba(91, 139, 199, 0.24);

/*border variables*/
$border: #e7effc;


/* dark theme */
$dark-theme:#243253;

	

